import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index';
import { SettingsContext } from '../../contexts';
import FilterAccordionChild from './FilterAccordionChild';
import SortingAccordionChild from './SortingAccordionChild';
import { useGlobalDictionaryQuery } from '../../hooks/utils/useGlobalDictionaryQuery';

interface Props {
  allProductsRooms: [];
  allProductsCollections: [];
  allProductsColors: [];
}

export default function AllProductsFilter(props: Props) {
  const { allProductsRooms, allProductsCollections, allProductsColors } = props;

  const {
    isFilterOpen,
    setFilterOpen,
    roomFilter,
    setRoomFilter,
    collectionFilter,
    setCollectionFilter,
    colorFilter,
    setColorFilter,
    priceFilter,
    setPriceFilter,
    productAvailabelityFilter,
    setProductAvailabelityFilter,
    productSorting,
    setProductSorting,
  }: {
    isFilterOpen: any;
    setFilterOpen: any;
    roomFilter: any;
    setRoomFilter: any;
    collectionFilter: any;
    setCollectionFilter: any;
    colorFilter: any;
    setColorFilter: any;
    priceFilter: any;
    setPricefilter: any;
    productAvailabelityFilter: any;
    setProductAvailabelityFilter: any;
    productSorting: any;
    setProductSorting: any;
  } = useContext(SettingsContext);

  const dictionary = useGlobalDictionaryQuery();
  const dataProductAvailabilityEN = ['In Stock', 'Pre Order'];
  const dataProductAvailabilityNL = ['Voorraad', 'Voorafgaande Bestelling'];

  const wrapperSpring = useSpring({
    pointerEvents: isFilterOpen ? 'auto' : 'none',
    backgroundColor: isFilterOpen ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)',
    cursor: isFilterOpen ? 'pointer' : 'auto',
  });

  const filterSpring = useSpring({
    transform: isFilterOpen ? 'translateX(-100%)' : 'translateX(0%)',
    config: {
      tension: 160,
      friction: 25,
    },
    delay: 200,
  });

  // Handle Option Color
  let allProductsColorsFilter = [];
  if (dictionary.node_locale === 'nl') {
    allProductsColorsFilter = allProductsColors.filter(
      item => item !== 'Natural' && item !== 'Charcoal'
    );
  }

  const filter = [
    {
      title: 'Price',
      displayTitle: dictionary.price,
      content: false,
    },
    {
      title: 'product-availabelity',
      displayTitle: dictionary.titleFilterStock,
      content:
        dictionary.node_locale === 'en' ? dataProductAvailabilityEN : dataProductAvailabilityNL,
    },
    {
      title: 'Colors',
      displayTitle: dictionary.colors,
      content: allProductsColorsFilter.length !== 0 ? allProductsColorsFilter : allProductsColors,
    },
    // {
    //   title: 'Collections',
    //   displayTitle: dictionary.collections,
    //   content: allProductsCollections,
    // },
    {
      title: 'Rooms',
      displayTitle: dictionary.rooms,
      content: allProductsRooms,
    },
    // {
    //   title: 'newst',
    //   displayTitle: 'Newst',
    //   content: dataProductSortingEN,
    // },
  ];

  const sorting = {
    displayTitle: 'Sorting',
    content: ['NEWEST', 'Ascending (A - Z)', 'Descending (Z - A)'],
  };

  return (
    <>
      <Background style={{ ...wrapperSpring }} onClick={() => setFilterOpen(false)} />
      <WrapperFilters style={{ ...filterSpring }}>
        <FilterHeader>
          <p>{dictionary.filters}</p>
          <button type="button" aria-label="close filters" onClick={() => setFilterOpen(false)}>
            {dictionary.close}
          </button>
        </FilterHeader>
        <ScrollOuter>
          <ScrollInner>
            <FilterAccordionWrapper>
              <SortingAccordionChild
                displayTitle={sorting.displayTitle}
                content={sorting.content}
                productSorting={productSorting}
                setProductSorting={setProductSorting}
              />
              {filter.map(({ title, displayTitle, content }, index) => (
                <FilterAccordionChild
                  filterIndex={index}
                  title={title}
                  displayTitle={displayTitle}
                  content={content}
                  key={title}
                  roomFilter={roomFilter}
                  setRoomFilter={setRoomFilter}
                  collectionFilter={collectionFilter}
                  setCollectionFilter={setCollectionFilter}
                  colorFilter={colorFilter}
                  setColorFilter={setColorFilter}
                  priceFilter={priceFilter}
                  setPriceFilter={setPriceFilter}
                  productAvailabelityFilter={productAvailabelityFilter}
                  productSorting={productSorting}
                  setProductSorting={setProductSorting}
                  setProductAvailabelityFilter={setProductAvailabelityFilter}
                />
              ))}
            </FilterAccordionWrapper>
          </ScrollInner>
        </ScrollOuter>
        <SearchFooter>
          <button type="button" aria-label="apply filters" onClick={() => setFilterOpen(false)}>
            {dictionary.apply}
          </button>
        </SearchFooter>
      </WrapperFilters>
    </>
  );
}

const Background = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: ${zIndex.header + 2};
  ${desktopBreakpoint} {
    width: 100vw;
  }
`;

const WrapperFilters = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  right: -100vw;
  background-color: ${colors.black};
  z-index: ${zIndex.header + 2};
  cursor: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${desktopBreakpoint} {
    width: ${desktopVW(480)};
    right: ${desktopVW(-481)};
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${mobileVW(80)};
  border-bottom: ${mobileVW(1)} solid #3c3b3b;
  padding: 0 ${mobileVW(30)};

  p {
    color: ${colors.white};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${mobileVW(15)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(13)};
    }
  }

  button {
    color: rgba(245, 245, 245, 0.4);
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${mobileVW(15)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(13)};
    }
  }

  ${desktopBreakpoint} {
    height: ${desktopVW(120)};
    border-bottom: ${desktopVW(1)} solid #3c3b3b;
    padding: ${desktopVW(10)} ${desktopVW(60)} 0 ${desktopVW(60)};
  }
`;

const FilterAccordionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${mobileVW(0)};
  ${desktopBreakpoint} {
  }
`;

const ScrollOuter = styled.div`
  position: relative;
  z-index: 1;
  overflow-y: scroll;
  width: 100%;
  flex: 2 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollInner = styled.div`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchFooter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${mobileVW(20)} ${mobileVW(20)} ${mobileVW(30)} ${mobileVW(20)};

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    color: ${colors.white};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    height: ${mobileVW(70)};
    font-size: ${mobileVW(15)};

    ${desktopBreakpoint} {
      height: ${desktopVW(70)};
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    padding: ${desktopVW(20)} ${desktopVW(60)} ${desktopVW(55)} ${desktopVW(60)};
  }
`;
const AccordionTitle = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${mobileVW(80)};
  width: 100%;
  text-transform: uppercase;
  font-family: 'messinaMono';
  color: ${colors.white};
  font-size: ${mobileVW(15)};

  ${desktopBreakpoint} {
    height: ${desktopVW(70)};
    font-size: ${desktopVW(13)};
    padding: 0;
  }
`;
